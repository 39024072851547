import Header from "./Header";
import Services from "./Services";
import { BrowserRouter as Router, Route} from "react-router-dom";
import Home from "./Home";
import Footer from "./Footer";
import Pricing from "./Pricing";
import Contact from "./Contact";
import About from "./About";

function App() {
  return (
    <Router>
      <Header />
      <Route exact path="/" component={Home}/>
      <Route exact path="/services" component={Services}/>
      <Route exact path="/pricing" component={Pricing}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/about" component={About}/>
      <Footer />
    </Router>
  );
}

export default App;
