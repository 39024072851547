function Home() {
  function hero() {
    return (
      <section id="hero" className="d-flex flex-column justify-content-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h1>BEAT</h1>
              <h2>THE IMPOSSIBLE</h2>
              {/* <a
        href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
        className="venobox play-btn mb-4"
        data-vbtype="video"
        data-autoplay="true"
      ></a> */}
            </div>
          </div>
        </div>
      </section>
    );
  }

  function whatWeDo() {
    return (
      <section id="about" className="about">
        <div className="container">
          <div className="row content">
            <div className="col-lg-6">
              <div className="section-title">
                <h2>What is Fitzz-B ?</h2>
              </div>
              <p>
                Fitzz-B Business Corporation mainly focuses on providing
                Business Development as a Service to clients who require
                Development.
                
                We focus on the Niche Factor of our
                clients to develop their Business in various formats.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <div className="section-title">
                <h2>What do we do ?</h2>
              </div>
              <p>
              We help our clients to find solutions to their
              Business Difficulties and we work with them
              to enhance and grow their businesses to
              higher levels in all aspects of their work.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function goal() {
    return (
      <section id="goal" className="goal">
        <div className="container">
          <h2>Our Goal</h2>
          <p>
            To be a World-Class Business Development Service provider and form a
            Business Networking Community, which provides Business Intelligence
            and Business Connections that helps various Budding Business
            Structures to Evolve and Sustain.
          </p>
        </div>
      </section>
    );
  }

  function features() {
    return (
      <section id="features" className="features">
        <div className="container">
          <div className="row">
            <div
              className="image col-lg-6 order-2 order-lg-1"
              style={{ backgroundImage: `url(assets/img/work.jpg)` }}
            ></div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="icon-box mt-5 mt-lg-0">
                <i className="bx bx-building"></i>
                <h4>Whom do we work for ?</h4>
                <p>
                  It’s a well-known fact that Development and Growth is an
                  essential part to sustain and survive.
                  Each sector seeks growth and development. There is
                  always a need for Development. Therefore our services aren’t
                  restricted to certain groups or clients.
                </p>
              </div>
              <div className="icon-box mt-5">
                <i className="bx bx-cube-alt"></i>
                <h4>How do we work?</h4>
                <p>
                First and foremost we  focus on communication with our clients, to get to know their  Business. We gather information, which is necessary to analyse. Based on the analysis, we discover the potential solution and development  to resolve drawbacks and enhance the growth of their Business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div>
      {hero()};{whatWeDo()};{goal()};{features()}
    </div>
  );
}

export default Home;
