import emailjs from "emailjs-com";
import apikeys from "../apikeys";

function Contact() {
  let name = "";
  let email = "";
  let message = "";

  const sendEmail = (e) => {

    if (name === "" || email === "" || message === "") {
      e.preventDefault();
      alert("Please fill the required feilds");
      return;
    }

    emailjs
      .sendForm(
        apikeys.SERVICE_ID,
        apikeys.QUERY_TEMPLATE_ID,
        e.target,
        apikeys.USER_ID
      )
      .then(
        (result) => {
          console.log("Message Sent, I'll get back to you shortly");
          console.log(result.text);
        },
        (error) => {
          console.log("An error occured, Plese try again");
          console.log(error.text);
        }
      );
    alert("Message sent will contact you soon");
    console.log(e.target);
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title mt-5">
          <h2>Contact</h2>
        </div>
      </div>

      <div>
        {/* <iframe
          style="border: 0; width: 100%; height: 350px"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
          frameborder="0"
          allowfullscreen
        ></iframe> */}
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className="ri-map-pin-line"></i>
                <h4>Location:</h4>
                <p>9A-Singarathope, Trichy-8, Tamil Nadu, India</p>
              </div>

              <div className="email">
                <i className="ri-mail-line"></i>
                <h4>Email:</h4>
                <p>fitzzbbusinesscorp@gmail.com</p>
              </div>

              <div className="phone">
                <i className="ri-phone-line"></i>
                <h4>Call:</h4>
                <p>+91 74023 32786</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
            <form className="php-email-form" onSubmit={sendEmail}>
              <div className="form-row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    onChange={(e) => (name = e.target.value)}
                    placeholder="Your Name*"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
                <div className="col-md-6 form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    onChange={(e) => (email = e.target.value)}
                    placeholder="Your Email*"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div className="validate"></div>
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  onChange={(e) => (message = e.target.value)}
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message*"
                ></textarea>
                <div className="validate"></div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
