function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <h3>Fitzz-B</h3>
        <p>
        We help to Grow your Business
        </p>
        <div className="social-links">
          <a href="/" className="twitter">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="https://www.facebook.com/hr1756/" className="facebook">
            <i className="bx bxl-facebook"></i>
          </a>
      
          <a href="https://instagram.com/fitzzb_business_corp?igshid=14bzowafln6uk" className="instagram">
            <i className="bx bxl-instagram"></i>
          </a>
          

          <a href="https://www.linkedin.com/in/fitzz-b-business-corp-410806206" className="linkedin">
          <i className="bx bxl-linkedin"></i>
          </a>
        </div>
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Fitzz-B Business Corporation</span>
          </strong>
          . All Rights Reserved
        </div>
        {/* <div className="credits">
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
