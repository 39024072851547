import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import apikeys from "../apikeys";
import emailjs from "emailjs-com";

function Pricing() {
  const [popUp, setPopup] = useState(null);
  let name = "";
  let email = "";
  let num = "";

  var packagesList = [
    {
      packageName: "ESSENTIAL",
      description:
        "Business Analysis is done to identify the current state model and an Analytical Forecast is engaged to identify potential development needed for the business.",
      isRecommended: false,
    },
    {
      packageName: "BASIC ",
      description:
        "Along with Essential, a report is generated, to show the analytical status of the business and development ideas with their feasibility charts.",
      isRecommended: false,
    },
    {
      packageName: "PROFESSIONAL",
      description:
        "Along with Essential, the developmental Ideas proposed will be implemented as per the needs of the clients.",
      isRecommended: true,
    },
    {
      packageName: "CONTRACT",
      description:
        "We uplift the business by engaging ourselves entirely with our clients for a period to bring out optimal growth in their business.",
      isRecommended: false,
    },
  ];

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || num === "") {
      alert("Please enter all information");
      return;
    }

    emailjs
      .sendForm(
        apikeys.SERVICE_ID,
        apikeys.PACKAGE_TEMPLATE_ID,
        e.target,
        apikeys.USER_ID
      )
      .then(
        (result) => {
          console.log("Message Sent, I'll get back to you shortly");
          console.log(result.text);
        },
        (error) => {
          console.log("An error occured, Plese try again");
          console.log(error.text);
        }
      );
    setPopup(null);
    alert("Thank you for choosing Fitzzb your interest has been notified will contact you soon");
    console.log(e.target);
  };

  const successAlert = (packageName, description) => {
    setPopup(
      <div>
        <Dialog open={true} aria-labelledby="form-dialog-title">
          <form className="contact-form" onSubmit={sendEmail}>
            <DialogTitle
              id="form-dialog-title"
              name="packageName"
              value={packageName}
            >
              {packageName}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{description}</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                onChange={(e) => (name = e.target.value)}
                fullWidth
              />
              <br></br>
              <TextField
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                onChange={(e) => (email = e.target.value)}
                fullWidth
              />
              <br></br>
              <TextField
                margin="dense"
                id="name"
                name="phone"
                label="Phone Number"
                type="tel"
                onChange={(e) => (num = e.target.value)}
                fullWidth
              />
              <input
                type="hidden"
                name="packageName"
                value={packageName}
              ></input>
            </DialogContent>
            <DialogActions>
              <Button
                className="btn btn-warning"
                onClick={() => {
                  setPopup(null);
                }}
                color="primary"
              >
                Cancel
              </Button>
              <input
                className="btn btn-primary"
                type="submit"
                value="Send"
              />
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  };

  function packages() {
    return packagesList.map((item, index) => (
      <div key={index} className="col-lg-3 col-md-6 package">
        <div className={item.isRecommended ? "box recommended" : "box"}>
          <h3>{item.packageName}</h3>
          {item.isRecommended && (
            <span className="recommended-badge">Recommended</span>
          )}
          <ul>
          <div style={{height:"150px"}} >
            <li>{item.description}</li>
          </div>
          </ul>
          <div className="btn-wrap">
            {popUp}
            <button
              className="btn-buy buy"
              onClick={() => successAlert(item.packageName, item.description)}
            >
              Get In Touch
            </button>
          </div>
        </div>
      </div>
    ));
  }

  return (
    <section id="pricing" className="pricing">
      <div className="container">
        <div className="section-title mt-5 ">
          <h2>Packages</h2>
        </div>

        <div className="row">{packages()}</div>
      </div>
    </section>
  );
}

export default Pricing;
