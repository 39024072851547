function About() {
  const team = [
    {
      name: "Fahad",
      role: "Business Analyst",
      brief: "He along with his friends started Fitzz-B Business Corporation in the year 2020 to provide a World Class Business Development as a Service. He currently operates as the Managing Lead for Fitzz-B. His aim is to form a Business Networking Community which provides Business Intelligence and Business Connections that helps various Budding Business Structures to Develop and Sustain.",
      img: "assets/img/team/fahad.jpg",
      email: "fahadfitzz@gmail.com",
    },
    {
      name: "Godwin Gaitan",
      role: "Technical Analyst",
      brief: "He is the bridge between businesses and technical coworkers. Analyzes the business difficulties and provide a reliable solution using cutting-edge technology. ",
      img: "assets/img/team/gaitan.jpg",
      email: "godwingaitan@gmail.com",
    },
    {
      name: "Shree Hari",
      role: "Business Analyst",
      brief: "He is the operations lead for FitzzB, enjoys empowering business cons into business solutions. Lead the team with strategical approach and gives best of his intelligence to  Fitzz-B stand out of crowd. Leading a adventures life by working on his passion with abundant challenges every day.",
      img: "assets/img/team/shrihari.jpg",
      email: "shrihari1002@gmail.com",
    },
    {
      name: "Santhosh",
      role: "Designer",
      brief: "Creative graphic designer with innovative ideas and a unique approach to visuals. Work on Building ideas from ashes to stupendous. Proficient in all major computer design software. With over 50+ project experience",
      img: "assets/img/team/santy.jpg",
      email: "santhosharunartist@gmail.com",
    },
    {
      name: "Raja Ram",
      role: "Technical Analyst",
      brief: "He is Technical lead of Fitzzb and the architect of our product’s strategic direction and leading Fitzzb through the transformation that is defining a new era in the Business industry with inovation",
      img: "assets/img/team/raja.jpg",
      email: "rajaramb99@gmail.com",
    },
  ];

  function infoCard() {
    return (
      <div className="container">
      <div className="row justify-content-center">
        {team.map((mate) => (
          <div className="col-lg-4 col-md-6 mt-4">
            <div className="box" style={{
              height: "850px",
            
            }}>
              <div
                style={{
                  height: "280px",
                
                }}
                className="container"
              >
                <img
                  src={mate.img}
                  alt="logo"
                  className="img-fluid"
                  style={{
                    marginTop:"10%",
                    borderRadius: "50%",
                    width:"100%",
                    height:"100%",
                    objectFit: "cover",
                  }}
                ></img>
              </div>

              <div class="container">
                <h3 style={{marginTop:"20px"}} >{mate.name}</h3>
                <h3 class="title">{mate.role}</h3>
                <div style={{height: "300px"}} ><p>{mate.brief}</p></div>
                
                <a href={`mailto:${mate.email}`}>
                  <i style={{fontSize:"20px"}} className="bx bx-mail-send"></i> : {mate.email}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
    );
  }

  return (
    <section id="aboutus" className="aboutus">
      <div className="container">
        <div className="section-title mt-5">
          <h2>About us</h2>
          
        </div>
        {infoCard()}
      </div>
    </section>
  );
}

export default About;
