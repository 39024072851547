import { useState } from "react";

function Services() {
  const [elaborate, toggleElaborate] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  var servicesList = [
    {
      name: "Business Analysis",
      icon: "bx bx-bar-chart-alt",
      about:
        "In Business Analysis we identify the business needs of our clients and determine solutions to their business problems. Solutions often include a software-systems development component, but may also consist of process improvements, organizational change, or strategic planning and policy development.",
    },
    {
      name: "Business Management",
      icon: "bx bx-book-alt",
      about:
        "In Business Management we offer management solutions for our clients to overcome their managerial problems and help them in structuring and framing their workflow. We focus on the roots and specific points of their management systems to resolve errors and optimize the systems. Our Work is based upon End-Results and Strategic Planning which pave a path to enhancement and development.",
    },
    {
      name: "Business Marketing",
      icon: "bx bx-briefcase-alt-2",
      about:
        "In Business Marketing, we help our clients to sell their products or services to other companies and organizations. We help them out to promote their business, we build a sustainable marketing system that strives to improve their revenues and profits.",
    },
    {
      name: "Outsourcing",
      icon: "bx bx-meteor",
      about:
        "Outsourcing is a strategic decision that reduces costs and increases efficiency. In Business Outsourcing, we help our clients to find a perfect outsourcer to get their work done cost-efficiently. We stand as a bridge to maintain a healthy relationship between our clients and the outsourcers.",
    },
    {
      name: "Idea Generation",
      icon: "bx bx-brain",
      about:
        "Idea generation is the process of creating, developing, and building a visual idea. Ideas are the key to innovation. Without them, there isn't much to execute, new ideas are necessary for Businesses to make any kind of improvement. We work on gathering new ideas, researching, testing, editing, revising, and ultimately implementing the plan for our clients.",
    },
    {
      name: "Startup Catalyst",
      icon: "bx bx-rocket",
      about:
        "Getting validation for any idea is important before taking off. Whether you are at the seed stage, expansion stage, or growing into a small business, we can help you innovate, validate, your products/services. We discuss your idea, problems, target customers, goals, and other things to deliver an optimal solution. After analyzing the pros and cons of the technology stack, we select the best framework for development. \n We help our clients from the very beginning and even after the launch. Launch your startup journey and accelerate your business growth by partnering with Fitzz-B.",
    },
  ];

  function expandText(index) {
    elaborate[index] = !elaborate[index];
    toggleElaborate([...elaborate]);
  }

  function services() {
    return servicesList.map((service, index) => (
      <div key={index} className="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div className="icon-box">
          <div className="icon">
            <i className={service.icon}></i>
          </div>
          <h4 onClick={() => expandText(index)}>{service.name}</h4>
          <p>
            {elaborate[index]
              ? service.about
              : `${service.about.substring(0, 200)}...`}
          </p>
        </div>
      </div>
    ));
  }

  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title mt-5">
          <h2>Service</h2>
        </div>

        <div className="row">{services()}</div>
      </div>
    </section>
  );
}

export default Services;
